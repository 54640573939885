import React, { useEffect } from 'react';
import '../styles/styles.scss'
import Logo from '../images/cyclone.inline.svg'
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// markup
const IndexPage = ({data}) => {
  const releases = data.allReleasesYaml.edges

  const colorChange = () => {
      document.documentElement.style.setProperty('--headerColor', 'transparent')
      document.documentElement.style.setProperty('--textColor', 'white')
      document.documentElement.style.setProperty('--barColor', 'transparent')
      document.documentElement.style.setProperty('--accentColor', 'rgb(247, 23, 53)')
      document.documentElement.style.setProperty('--backgroundColor', 'rgb(1, 22, 39)')
  }

  useEffect(() => {
      colorChange()
    }
  )


  return (
    <main className="main releaseGridLayout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cyclone</title>
        <link rel="stylesheet" href="https://use.typekit.net/vso5rvi.css" />
      </Helmet>
      <header className="logoHeader">
        {/* <h1>Cyclone</h1> */}
        <Logo alt={'Cyclone'} />
      </header>
      <ul className="releaseGrid">
        {releases.map(release => (
          <ReleaseItem release={release} key={release.node.id} />
        ))}
      </ul>
    </main>
  )
}

export default IndexPage

/* Components */
const ReleaseItem = ({release}) => {
  const image = getImage(release.node.cover)

  return <li className="releaseItem">
    <Link to={`/${release.node.releaseNum}`}>
      <GatsbyImage image={image} alt={`Cyclone ${release.node.releaseNum}`} />
    </Link>
  </li>
}

export const query = graphql `
query {
  allReleasesYaml(sort: {fields: releaseNum, order: DESC}) {
    edges {
      node {
        cover {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        id
        tracks {
          artist
          title
          duration
        }
        releaseNum
        text
      }
    }
  }
}
`